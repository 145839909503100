import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ routePermission, children }) => {

    const { permisos } = useSelector(state => state.auth.usuario);
    const { isAuthenticated: isAuthenticatedSesion } = useSelector(state => state.auth);

    let acceso_permiso = true;
    let isAuthenticated = useAuth();

    if(permisos){
        if(routePermission){
            acceso_permiso = ObjectByString(permisos, routePermission);

            if(!acceso_permiso){
                return <Navigate to='/login' replace />;
            }

            return children ? children : <Outlet />;
        }else{
            if (!isAuthenticated){
                return <Navigate to='/login' replace />;
            }
        
            return children ? children : <Outlet />;
        }
    }else{
        if (!isAuthenticated){
            return <Navigate to='/login' replace />;
        }

        return children ? children : <Outlet />;
    }
}

const useAuth = () => {
    const token = localStorage.getItem('token');

    if(token){
          return true;
    }else{
          return false;
    }
}

const ObjectByString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

export default PrivateRoute;