import { SHOW_LOADER, HIDE_LOADER } from '../actions/types'

const initialState = false;

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case SHOW_LOADER:
            return true;
        case HIDE_LOADER:
            return false;
        default:
            return state;
    }
}