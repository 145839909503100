import { REPORTES } from "../actions/types";

const initialState = {
    listado_requerimientos: []
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case REPORTES.BUSCAR_REQUERIMIENTOS:
            return {
                ...state,
                listado_requerimientos: payload
            };

        default:
            return state;
    }
}