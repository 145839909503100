import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { crearUsuario } from '../../actions/usuarios';

const NuevoUsuario = ({ usuario, reestablecerUsuario }) => {

    const dispatch =  useDispatch();

    const [nuevoModal, setNuevoModal] = useState(false);
    const [statusProcesar, setStatusProcesar] = useState(false);

    const [usuarioData, setUsuarioData] = useState({
        id_empresa: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        tipo: 7,
        email: '',
        password: '',
        passwordConf: ''
    });

    /* Crear usuario */
    const handleInputChange = ({ target }) => {
        setUsuarioData({
            ...usuarioData,
            [target.name]: target.value
        });
    }
    
    const handleProcesar = async () => {
        if(usuarioData.nombre && usuarioData.apellido_paterno && usuarioData.email && usuarioData.password && usuarioData.passwordConf){
            if(usuarioData.password !== usuarioData.passwordConf){
                await dispatch(setAlert('Las contraseñas no coinciden', 'danger'));
            }else{
                await setStatusProcesar(true);
                await dispatch(crearUsuario(usuarioData));
                await handleCerrar();
                await setStatusProcesar(false);
            }
        }else{
            await dispatch(setAlert('Todos los campos son obligatorios', 'danger'));
        }
    }

    const handleCerrar = () => {
        if(!statusProcesar){
            setUsuarioData({
                ...usuarioData, 
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                email: '',
                password: '',
            });
            setNuevoModal(false);
            reestablecerUsuario(null);
        }
    }

    /* Funciones generales */
    const onViewPassword = (origen) => {
        if(usuarioData.password !== ''){
            let loginInputType = document.getElementById(origen).type;
            
            if(loginInputType === 'password')document.getElementById(origen).type = 'text';
            else document.getElementById(origen).type = 'password';
        }
    }

    useEffect(() => {
        if(usuario){
            setNuevoModal(true);

            setUsuarioData({
                ...usuarioData,
                id_empresa: usuario.id_empresa
            });
        }        
    }, [usuario]);

    return (
        <Modal show={nuevoModal} size="lg" backdrop="static" centered onHide={() => handleCerrar()}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Nombre" id="nombre" name="nombre" value={usuarioData.nombre} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="nombre">Nombre</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido paterno" id="apellido_paterno" name="apellido_paterno" value={usuarioData.apellido_paterno} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Apellido materno" id="apellido_materno" name="apellido_materno" value={usuarioData.apellido_materno} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="apellido_materno">Apellido materno</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Correo" id="email" name="email" value={usuarioData.email} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="email">Correo</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="password" className="form-control" placeholder="Contraseña" id="password" name="password" value={usuarioData.password} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="password">Contraseña</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('password')}><i className="fa-solid fa-eye"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="password" className="form-control" placeholder="Confirmar Contraseña" id="passwordConf" name="passwordConf" value={usuarioData.passwordConf} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="passwordConf">Confirmar contraseña</label>
                            </div>
                            <div className="input-group-append">
                                <button className="btn btn-primary viewPassword" onClick={() => onViewPassword('passwordConf')}><i className="fa-solid fa-eye"></i></button>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcesar ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Agregar</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcesar()}><i className="fas fa-check fa-sm"></i> Agregar</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default NuevoUsuario;