export const formatNumber = (numero, decimales, prefijo, sufijo) => {
    let number = (Math.round(numero * 100) / 100).toFixed(decimales);
    const decimalsFormated = number.toLocaleString(undefined, { maximumFractionDigits: decimales });
    const finalFormated = prefijo + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufijo;
    return finalFormated;    
}

//Ordenamiento numerico
export const sortTableNumberLimite = (primera, segunda) => {
    if(!isNaN(primera.limite)){
        let a = Number(primera.limite);
        let b = Number(segunda.limite);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberReservaciones = (primera, segunda) => {
    if(!isNaN(primera.reservaciones)){
        let a = Number(primera.reservaciones);
        let b = Number(segunda.reservaciones);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberConfirmaciones = (primera, segunda) => {
    if(!isNaN(primera.confirmaciones)){
        let a = Number(primera.confirmaciones);
        let b = Number(segunda.confirmaciones);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberSolicitantes = (primera, segunda) => {
    if(!isNaN(primera.solicitantes)){
        let a = Number(primera.solicitantes);
        let b = Number(segunda.solicitantes);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberSeleccionados = (primera, segunda) => {
    if(!isNaN(primera.seleccionados)){
        let a = Number(primera.seleccionados);
        let b = Number(segunda.seleccionados);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberCupo = (primera, segunda) => {
    if(!isNaN(primera.cupo)){
        let a = Number(primera.cupo);
        let b = Number(segunda.cupo);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberSolicitados = (primera, segunda) => {
    if(!isNaN(primera.solicitados)){
        let a = Number(primera.solicitados);
        let b = Number(segunda.solicitados);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberRealizados = (primera, segunda) => {
    if(!isNaN(primera.realizados)){
        let a = Number(primera.realizados);
        let b = Number(segunda.realizados);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableNumberJovenes = (primera, segunda) => {
    if(!isNaN(primera.jovenes)){
        let a = Number(primera.jovenes);
        let b = Number(segunda.jovenes);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

//Ordenamiento de status
export const sortTableStatusStatus = (primera, segunda) => {
    if(!isNaN(primera.status)){
        let a = Number(primera.status);
        let b = Number(segunda.status);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableStatusValorStatus = (primera, segunda) => {
    if(!isNaN(primera.valor?.status)){
        let a = Number(primera.valor?.status);
        let b = Number(segunda.valor?.status);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableStatusConfirmado = (primera, segunda) => {
    let a = String(primera.confirmado);
    let b = String(segunda.confirmado);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

export const sortTableStatusCanjeado = (primera, segunda) => {
    let a = String(primera.canjeado);
    let b = String(segunda.canjeado);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

//Ordenamiento de fechas
export const sortTableDateInicio = (primera, segunda) => {
    let a = new Date(primera.inicio);
    let b = new Date(segunda.inicio);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateFin = (primera, segunda) => {
    let a = new Date(primera.fin);
    let b = new Date(segunda.fin);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateFechaInicio = (primera, segunda) => {
    let a = new Date(primera.fecha_inicio);
    let b = new Date(segunda.fecha_inicio);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateFechaFin = (primera, segunda) => {
    let a = new Date(primera.fecha_fin);
    let b = new Date(segunda.fecha_fin);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateSolicitud = (primera, segunda) => {
    let a = new Date(primera.solicitud);
    let b = new Date(segunda.solicitud);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateFecha = (primera, segunda) => {
    let a = new Date(primera.fecha);
    let b = new Date(segunda.fecha);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateLimite = (primera, segunda) => {
    let a = new Date(primera.limite);
    let b = new Date(segunda.limite);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateCreacion = (primera, segunda) => {
    let a = new Date(primera.creacion);
    let b = new Date(segunda.creacion);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateReservacion = (primera, segunda) => {
    let a = new Date(primera.reservacion);
    let b = new Date(segunda.reservacion);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateConfirmacion = (primera, segunda) => {
    let a = new Date(primera.confirmacion);
    let b = new Date(segunda.confirmacion);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateFechaAcceso = (primera, segunda) => {
    let a = new Date(primera.fecha_acceso);
    let b = new Date(segunda.fecha_acceso);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const sortTableDateCanje = (primera, segunda) => {
    let a = new Date(primera.canje);
    let b = new Date(segunda.canje);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}