import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Breadcrumb = () => {

    const secciones = useSelector(state => state.breadcrumb);
    
    return (
        (secciones.length > 0)
            &&
                <div className="m-2 p-1">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb m-0">
                            {
                                secciones.map(seccion => {
                                    return(
                                        <li key={seccion.nombre} className="breadcrumb-item">
                                            {
                                                (seccion.activo)
                                                    ?
                                                        <span className="bread-active">{seccion.nombre}</span>
                                                    :
                                                        <Link className="bread-link" to={seccion.path}>{seccion.nombre}</Link>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </nav>
                </div>
    )
}

export default Breadcrumb;