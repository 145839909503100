import { EDITAR_BREADCRUMB } from "../actions/types";

const initialState = [];

const breadcrumb = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EDITAR_BREADCRUMB:
            return payload
        default:
            return state;
    }

}

export default breadcrumb;