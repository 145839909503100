import { USUARIO } from '../actions/types';

const initialState = {
    listado: [],
    detalles: null, 
    ventana_filtros: false, 
    filtros: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case USUARIO.BUSCAR:
            return {
                ...state,
                listado: payload
            };

        case USUARIO.AGREGAR:
            let usuarios_agregar = state.listado;

            usuarios_agregar = [
                ...usuarios_agregar, payload
            ];

            return {
                ...state, 
                listado: usuarios_agregar
            };

        case USUARIO.DETALLES:
            return {
                ...state,
                detalles: payload
            };

        case USUARIO.EDITAR:
            let usuarios_editar = state.listado;

            usuarios_editar = usuarios_editar.map(usuario => usuario._id === payload._id ? payload : usuario);

            return {
                ...state, 
                listado: usuarios_editar
            };

        case USUARIO.ELIMINAR:
            return {
                ...state,
                listado: state.listado.filter( usuario => usuario._id !== payload.id )
            };

        case USUARIO.FILTROS:
            return {
                ...state,
                ventana_filtros: payload
            };

        default:
            return state;
    }
}