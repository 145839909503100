import { SHOW_LOADER, HIDE_LOADER } from './types'

export const changeLoader = show => dispatch => {
    if (show) {
        dispatch({
            type: SHOW_LOADER,
            payload: {}
        })
    } else {
        dispatch({
            type: HIDE_LOADER,
            payload: {}
        })
    }
}