import axios from 'axios';

import { USUARIO } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

export const buscarUsuarios = (filtros) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify(filtros);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/listar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.BUSCAR,
            payload: res.data.usuarios
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarUsuarios = () => dispatch => {
    dispatch({
        type: USUARIO.BUSCAR,
        payload: []
    });
}

export const buscarUsuariosTipo = (tipos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = {
        tipos
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/listar_tipo`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.BUSCAR,
            payload: res.data.usuarios
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const crearUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(usuario);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/crear`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.AGREGAR,
            payload: res.data.usuario
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const obtenerUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/detalles`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.DETALLES,
            payload: res.data.usuario
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const limpiarUsuario = () => dispatch => {
    dispatch({
        type: USUARIO.DETALLES,
        payload: null
    });
}

export const editarUsuario = (usuario) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = JSON.stringify(usuario);

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/editar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.EDITAR,
            payload: res.data.usuario
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const eliminarUsuario = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/usuarios/eliminar`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: USUARIO.ELIMINAR,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const filtarUsuarios = (opcion) => dispatch => {
    dispatch({
        type: USUARIO.FILTROS,
        payload: opcion
    });
}
