import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import Loader from './Loader';
import Breadcrumb from './Breadcrumb';
import Navbar from './Navbar';

const Layout = () => {

    const { isAuthenticated } = useSelector(state => state.auth);
    const loader = useSelector(state => state.loader);

    const layoutAdmin = (
        <Fragment>
            {loader && <Loader></Loader>}
            <Navbar></Navbar>
            <Breadcrumb />
        </Fragment>
    );

    const layoutGuest = (<Fragment></Fragment>);

    if(isAuthenticated){
        return layoutAdmin;
    } else{
        return layoutGuest;
    }
}

export default Layout;