import axios from 'axios';

import { REPORTES } from './types';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Generales */
export const buscarRequerimientos = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/reportes/requerimientos`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: REPORTES.BUSCAR_REQUERIMIENTOS,
            payload: res.data.requerimientos
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Internas */
export const generarReporteJovenes = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/jovenes`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteEmpresas = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/empresas`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteReservaciones = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/reservaciones`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteInscripciones = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/inscripciones`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteSolicitudes = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/solicitudes`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteEventos = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/eventos`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));

        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}

export const generarReporteAsesorias = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }

    const body = JSON.stringify(datos);

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/reportes/asesorias`, body, config);
        await dispatch(changeLoader(false));

        const file = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        //Open the URL on new Window
        window.open(fileURL);

    } catch (error) {
        await dispatch(changeLoader(false));
        
        if (error.response.data.errors) {
            error.response.data.errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
}